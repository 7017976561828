import React from 'react'
import ReactMarkdown from 'react-markdown'
import Image from '../image'
import { Carousel } from 'react-responsive-carousel'

const Text = ({ data, langKey, title }) => (
  <div className='mb-10'>
    {title && title[langKey] && <h3 className='text-2xl underline font-bold mb-4'>{title[langKey]}</h3>}
    <div>
      {
        data.map((el, i) => <div key={`txt_par_${i}`}><ReactMarkdown source={el[langKey]} /></div>)
      }
    </div>
  </div>
)

const ImageParagraph = ({ image }) => (<div className='mb-10'><Image imgRef={image} /></div>)

const ImagesParagraph = ({ images }) => (
  <div className='mb-10'>
    <Carousel showThumbs={false} showStatus={false} showIndicators={false} autoPlay interval={4000} infiniteLoop>
      {
        images.map((im, j) => <Image key={`c_mi_${j}`} imgRef={im} />)
      }
    </Carousel>
  </div>
)

const Points = ({ data, langKey, title }) => (
  <div className='mb-10'>
    {title && title[langKey] && <h3 className='text-2xl underline font-bold'>{title[langKey]}</h3>}
    <ul className='mt-4'>
      {
        data.map((el, i) => <li key={`point_par_${i}`} className='mb-6'><ReactMarkdown source={el[langKey]} /></li>)
      }
    </ul>
  </div>
)

const ParagraphBuilder = ({ paragraphs, langKey }) => (
  <>
    {
      paragraphs.map((p, i) => {
        switch (p.type) {
          case 'text':
            return (<div key={`paragraph_${i}`}><Text title={p.title} data={p.data} langKey={langKey} /></div>)
          case 'image':
            return (<div key={`paragraph_${i}`}><ImageParagraph image={p.images[0]} /></div>)
          case 'images':
            return (<div key={`paragraph_${i}`}><ImagesParagraph images={p.images} /></div>)
          case 'points':
            return (<div key={`paragraph_${i}`}><Points title={p.title} data={p.data} langKey={langKey} /></div>)
          default:
            return (<div key={`paragraph_${i}`} />)
        }
      })
    }
  </>
)

export default ParagraphBuilder
