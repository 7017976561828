import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ParagraphBuilder from '../components/ParagraphBuilder'

import Seo from '../components/seo'
import SecondaryHeading from '../components/SecondaryHeading'
import Section from '../components/Section'
import ReactMarkdown from 'react-markdown'

const Services = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          } 
          pagesData {
            quality {
              pageTitle {
                de
                en
                it
              }
              subtitle {
                de
                en
                it 
              }
              numeberSection {
                de
                en
                it
              }
              mainSection {
                paragraphs {
                  data {
                    de
                    en
                    it
                  }
                  title {
                    it
                    en
                    de
                  }
                  images
                  type
                }
                points {
                  title {
                    it
                    en
                    de
                  }
                  data {
                    it
                    en
                    de
                  }
                }
              }
            }
          }
        }
      }
    }
      `
  )
  const {
    pageTitle,
    mainSection,
    numeberSection
  } = site.siteMetadata.pagesData.quality

  return (
    <>
      <Seo title={pageTitle[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={pageTitle[langKey]} />
      <Section>
        <ol className='max-w-screen-lg m-auto px-6 md:px-0 text-2xl text-green-gefa mb-16'>
          {
            numeberSection.map((el, i) => <li className='font-bold text-2xl' key={`${i}`}>{el[langKey]}</li>)
          }
        </ol>
        <div className='max-w-screen-lg m-auto px-6 md:px-0'>
          <ParagraphBuilder paragraphs={mainSection.paragraphs} langKey={langKey} />
        </div>
        <div className='mb-10 max-w-screen-lg m-auto px-6 md:px-0'>
          {mainSection.points.title && mainSection.points.title[langKey] && <h3 className='text-2xl underline font-bold'>{mainSection.points.title[langKey]}</h3>}
          <ul className='mt-4'>
            {
              mainSection.points.data.map((el, i) => <li key={`point_par_${i}`} className='mb-6 text-green-gefa'><ReactMarkdown source={el[langKey]} /></li>)
            }
          </ul>
        </div>
      </Section>
    </>
  )
}

export default Services
